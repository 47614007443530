<template>
	<div class="pagination-progress">
		<span>{{ value }} von {{ max }}</span>
		<div>
			<progress
				:max="max"
				:value="value"
			/>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		max: {
			type: Number,
			required: true
		},
		value: {
			type: Number,
			required: true
		}
	}
};
</script>

<style lang="scss">
	.pagination-progress {
		margin: 1rem 0;

		progress[value] {
			appearance: none;
			display: inline-block;
			width: 12.5rem;
			height: 2px;
			background: $color-light;
			border: 0;

			&::-webkit-progress-bar {
				background-color: $color-border;
			}

			&::-webkit-progress-value {
				background-color: $color-text;
			}
		}

		div {
			display: flex;
			justify-content: center;
		}

		span {
			display: block;
			margin-bottom: 1rem;
		}
	}
</style>
